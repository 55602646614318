import React from 'react';
import { createUseStyles } from 'react-jss';
import Effective from '../../assets/skuteczne-cv.svg';
import Experience from '../../assets/doswiadczenie.svg';
import Satisfaction from '../../assets/zadowoleni-klienci.svg';
import classnames from 'classnames';

const useStyles = createUseStyles((theme) => ({
  container: {
    ...theme.minSidePadding,
    width: '100%'
  },
  belt: {
    height: '100%',
    maxWidth: 'var(--layout-width)',
    margin: [[0, 'auto']],
    display: 'grid',
    justifyContent: 'space-around',
    gridTemplateColumns:
      'repeat(3, minmax(15%, calc(var(--font-size-lg) * 12)))',
    gridTemplateRows: '55% 40%',
    gridAutoFlow: 'column',
    rowGap: '5%',
    textAlign: 'center'
  },
  icon: {
    fill: theme.yellow,
    width: '80%',
    height: '60%',
    justifySelf: 'center',
    alignSelf: 'end'
  },
  subtitle: {
    fontSize: 'var(--font-size-lg)',
    fontWeight: 700,
    justifySelf: 'center'
  },
  [`@media (max-width: ${theme.breakPoints.smMax})`]: {
    belt: {
      gridTemplateRows: '50% 45%'
    }
  },
  [`@media (max-width: ${theme.breakPoints.xsMax})`]: {
    belt: {
      gridTemplateRows: '47% 48%'
    }
  },
  [`@media (max-width: 300px)`]: {
    subtitle: {
      fontSize: 'var(--font-size-md)'
    }
  },
  [`@media (max-width: 200px)`]: {
    subtitle: {
      fontSize: 'var(--font-size-sm)'
    }
  }
}));

export const Belt = ({ containerClass, subtitleClass }) => {
  const classes = useStyles();
  return (
    <div className={classnames(containerClass, classes.container)}>
      <div className={classnames(classes.belt)}>
        <Effective className={classes.icon} />
        <h2 className={classnames(classes.subtitle, subtitleClass)}>
          200+ skutecznych CV
        </h2>
        <Experience className={classes.icon} />
        <h2 className={classnames(classes.subtitle, subtitleClass)}>
          15 lat doświadczenia
        </h2>
        <Satisfaction className={classes.icon} />
        <h2 className={classnames(classes.subtitle, subtitleClass)}>
          500+ zadowolonych klientów
        </h2>
      </div>
    </div>
  );
};
