import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from 'react-bootstrap/Button';
import { useYellowButton } from '../../hooks/useYellowButton';
import classNames from 'classnames';

const useStyles = createUseStyles((theme) => ({}));

export const ContactButton = ({ additionalClassName }) => {
  const classes = useStyles();
  const buttonClass = useYellowButton();
  return (
    <Button
      className={classNames(additionalClassName, buttonClass)}
      onClick={() => {
        const footer = document.getElementById('footer');
        footer.scrollIntoView(false);
      }}
    >
      Skontaktuj się
    </Button>
  );
};
