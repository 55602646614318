import React from 'react';
import { createUseStyles } from 'react-jss';
import { graphql, useStaticQuery } from 'gatsby';
import { ServiceCard } from './serviceCard';
import {
  BookHalf,
  Bullseye,
  ChatLeftFill,
  CheckCircle,
  LifePreserver,
  PersonCircle,
  Rulers,
  VectorPen
} from 'react-bootstrap-icons';
import classNames from 'classnames';

const useStyles = createUseStyles((theme) => ({
  container: {
    ...theme.minSidePadding,
    textAlign: 'center'
  },
  title: {
    fontSize: 'var(--font-size-xxl)',
    fontWeight: 600,
    margin: [['var(--font-size-xxl)', 0]]
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: `repeat(4, 1fr)`,
    gap: 'var(--font-size-xs)',
    margin: [0, 'auto'],
    maxWidth: 'var(--layout-width)'
  },
  [`@media (max-width: ${theme.breakPoints.lgMax})`]: {
    grid: {}
  },
  [`@media (max-width: ${theme.breakPoints.mdMax})`]: {
    grid: {
      gridTemplateColumns: '1fr 1fr'
    }
  },
  [`@media (max-width: ${theme.breakPoints.xsMax})`]: {
    grid: {
      gridTemplateColumns: `1fr`
    }
  }
}));

const icons = {
  bullseye: <Bullseye />,
  'ruler-combined': <Rulers />,
  'book-open': <BookHalf />,
  'user-circle': <PersonCircle />,
  'life-ring': <LifePreserver />,
  'check-circle': <CheckCircle />,
  comments: <ChatLeftFill />,
  'graduation-cap': <VectorPen />
};

export const ServicesGrid = (props) => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      allServicesYaml(sort: { fields: [position], order: ASC }) {
        nodes {
          cardText
          cardTitle
          icon
        }
      }
    }
  `);
  return (
    <div
      className={classNames(classes.container, props.className)}
      style={props.containerStyle}
    >
      <h2 className={classes.title}>Jak wyglądają nasze usługi</h2>
      <section className={classNames(classes.grid)}>
        {data.allServicesYaml.nodes.map(({ icon, ...other }) => (
          <ServiceCard key={icon} icon={icons[icon]} {...other} />
        ))}
      </section>
    </div>
  );
};
