import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  card: {
    textAlign: 'left',
    padding: 'var(--font-size-md)',
    '& svg': {
      display: 'inline',
      width: 'var(--font-size-xxl)',
      height: 'var(--font-size-xxl)'
    }
  },
  header: {
    margin: [0, 0, 'var(--font-size-md)'],
    textAlign: 'left'
  },
  title: {
    marginLeft: 'var(--font-size-xs)',
    fontSize: 'var(--font-size-md)',
    fontWeight: 700,
    display: 'inline',
    verticalAlign: 'middle'
  },
  text: {
    fontSize: 'var(--font-size-md)',
    fontWeight: 500
  }
}));

export const ServiceCard = ({ cardTitle, cardText, icon }) => {
  const classes = useStyles();
  return (
    <section className={classes.card}>
      <header className={classes.header}>
        {icon}
        <h2 className={classes.title}>{cardTitle}</h2>
      </header>
      <p className={classes.text}>{cardText}</p>
    </section>
  );
};
